<template>
    <div class="col-md-12">
        <div class="col-md-12 mt-20">
            <div class="col-md-12 row">
                <div class="col-md-9">
                    <h3 class="page-header">
                        <i class="fa fa fa-rupee animated bounceInDown show-info" style="font-size: 24px;"></i>
                        Salary Slip
                    </h3>
                </div>
                <!-- <div class="col-md-3"  >
                    <button class="btn btn-success" >+ Add New</button>
                    <button class="btn btn-info ml-10" >Refresh</button>
                </div> -->
            </div>
        </div>
        <div class="col-md-12 pt-10">
            <div class="">
                <table class="table ">
                    <thead>
                        <th>S.No.</th>
                        <th>Employee Name</th>
                        <th>Month/Year</th>
                        <th>Basic</th>
                        <th>Fuel</th>
                        <th>Add. Sale Inc.</th>
                        <th>Leaves</th>
                        <th>Delivery</th>
                        <!-- <th>Action</th> -->
                    </thead>
                    <tbody>
                        <tr v-for="item, index in salaryslipdata" :key="item.id">
                            <td>{{ index + 1 }}</td>
                            <td>{{ getEmployeeName(item)??'' }}</td>
                            <td>{{ item.month ?? '' }}/{{ item.year ?? '' }}</td>
                            <td>{{ item.basic ?? 0 }}</td>
                            <td>{{ item.fuel ?? 0 }}</td>
                            <td>{{ item.addsaleincentive ?? 0 }}</td>
                            <td>{{ item.leaves??0 }}</td>
                            <td>{{ item.delivery??0 }}</td>
                            <!-- <td>
                                <button class="badge bg-primary" @click="editSlip(item)">Edit</button>
                                <button class="badge bg-danger ml-5" @click="disableSlip(item)">Delete</button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'


export default {
    data(){
        return {
            salaryslipdata: [],
        }
    },
    mounted(){
        this.$store.dispatch('fetchemployees')
        this.refresh()
    },
    computed: {
        ...mapGetters([
            'employees'
        ])
    },
    methods: {
        refresh() {
            this.$store.commit('assignloadingstatus', 1)
            axios.post('api/salary/slip/fetch')
                .then((response) => {
                    if (response.data.salaryslips?.length > 0) {
                        this.salaryslipdata = response.data.salaryslips
                        this.$store.commit('assignloadingstatus', 0)
                        // this.cancel()
                    }
                })
        },
        getEmployeeName(item) {
            let find = this.employees.find(o => o.id == item.userid)
            return find?.name ?? ''
        },
    }
}
</script>